// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-meal-list-js": () => import("./../../../src/pages/work/mealList.js" /* webpackChunkName: "component---src-pages-work-meal-list-js" */),
  "component---src-pages-work-mobile-order-js": () => import("./../../../src/pages/work/mobileOrder.js" /* webpackChunkName: "component---src-pages-work-mobile-order-js" */),
  "component---src-pages-work-wishli-js": () => import("./../../../src/pages/work/wishli.js" /* webpackChunkName: "component---src-pages-work-wishli-js" */)
}

